import { render, staticRenderFns } from "./index.vue?vue&type=template&id=edb431c6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=edb431c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edb431c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderMenuBtn: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/MenuBtn.vue').default,AppMainLogo: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/MainLogo.vue').default,AppHeaderSearchInput: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/SearchInput.vue').default,AppHeaderUserDropdown: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/UserDropdown.vue').default,AppHeaderStoreBtn: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/StoreBtn.vue').default,AppHeaderFavorite: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/Favorite.vue').default,AppHeaderShoppingCartBtn: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/ShoppingCartBtn.vue').default,AppHeaderMenuBar: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/header/MenuBar.vue').default})
